import React, { useState, useEffect } from 'react';
import { Typeahead } from 'react-bootstrap-typeahead'; // Import Typeahead component
import 'react-bootstrap-typeahead/css/Typeahead.css'; // Import Typeahead CSS
import './otherss.css'; // Import your custom CSS
import './App.css'; // Import your custom CSS

const Otherss = () => {
  const [symptoms, setSymptoms] = useState('');
  const [predictionResult, setPredictionResult] = useState(null);
  const [loading, setLoading] = useState(false);
  const [options, setOptions] = useState([]);

  useEffect(() => {
    // Simulating fetching options from an API or database
    const allSymptoms = [
     "anxiety and nervousness",
"depression",
"shortness of breath",
"depressive or psychotic symptoms",
"sharp chest pain",
"dizziness",
"insomnia",
"abnormal involuntary movements",
"chest tightness",
"palpitations",
"irregular heartbeat",
"breathing fast",
"hoarse voice",
"sore throat",
"difficulty speaking",
"cough",
"nasal congestion",
"throat swelling",
"diminished hearing",
"lump in throat",
"throat feels tight",
"difficulty in swallowing",
"skin swelling",
"retention of urine",
"groin mass",
"leg pain",
"hip pain",
"suprapubic pain",
"blood in stool",
"lack of growth",
"emotional symptoms",
"elbow weakness",
"back weakness",
"pus in sputum",
"symptoms of the scrotum and testes",
"swelling of scrotum",
"pain in testicles",
"flatulence",
"pus draining from ear",
"jaundice",
"mass in scrotum",
"white discharge from eye",
"irritable infant",
"abusing alcohol",
"fainting",
"hostile behavior",
"drug abuse",
"sharp abdominal pain",
"feeling ill",
"vomiting",
"headache",
"nausea",
"diarrhea",
"vaginal itching",
"vaginal dryness",
"painful urination",
"involuntary urination",
"pain during intercourse",
"frequent urination",
"lower abdominal pain",
"vaginal discharge",
"blood in urine",
"hot flashes",
"intermenstrual bleeding",
"hand or finger pain",
"wrist pain",
"hand or finger swelling",
"arm pain",
"wrist swelling",
"arm stiffness or tightness",
"arm swelling",
"hand or finger stiffness or tightness",
"wrist stiffness or tightness",
"lip swelling",
"toothache",
"abnormal appearing skin",
"skin lesion",
"acne or pimples",
"dry lips",
"facial pain",
"mouth ulcer",
"skin growth",
"eye deviation",
"diminished vision",
"double vision",
"cross-eyed",
"symptoms of eye",
"pain in eye",
"eye moves abnormally",
"abnormal movement of eyelid",
"foreign body sensation in eye",
"irregular appearing scalp",
"swollen lymph nodes",
"back pain",
"neck pain",
"low back pain",
"pain of the anus",
"pain during pregnancy",
"pelvic pain",
"impotence",
"infant spitting up",
"vomiting blood",
"regurgitation",
"burning abdominal pain",
"restlessness",
"symptoms of infants",
"wheezing",
"peripheral edema",
"neck mass",
"ear pain",
"jaw swelling",
"mouth dryness",
"neck swelling",
"knee pain",
"foot or toe pain",
"bowlegged or knock-kneed",
"ankle pain",
"bones are painful",
"knee weakness",
"elbow pain",
"knee swelling",
"skin moles",
"knee lump or mass",
"weight gain",
"problems with movement",
"knee stiffness or tightness",
"leg swelling",
"foot or toe swelling",
"heartburn",
"smoking problems",
"muscle pain",
"infant feeding problem",
"recent weight loss",
"problems with shape or size of breast",
"underweight",
"difficulty eating",
"scanty menstrual flow",
"vaginal pain",
"vaginal redness",
"vulvar irritation",
"weakness",
"decreased heart rate",
"increased heart rate",
"bleeding or discharge from nipple",
"ringing in ear",
"plugged feeling in ear",
"itchy ear(s)",
"frontal headache",
"fluid in ear",
"neck stiffness or tightness",
"spots or clouds in vision",
"eye redness",
"lacrimation",
"itchiness of eye",
"blindness",
"eye burns or stings",
"itchy eyelid",
"feeling cold",
"decreased appetite",
"excessive appetite",
"excessive anger",
"loss of sensation",
"focal weakness",
"slurring words",
"symptoms of the face",
"disturbance of memory",
"paresthesia",
"side pain",
"fever",
"shoulder pain",
"shoulder stiffness or tightness",
"shoulder weakness",
"arm cramps or spasms",
"shoulder swelling",
"tongue lesions",
"leg cramps or spasms",
"abnormal appearing tongue",
"ache all over",
"lower body pain",
"problems during pregnancy",
"spotting or bleeding during pregnancy",
"cramps and spasms",
"upper abdominal pain",
"stomach bloating",
"changes in stool appearance",
"unusual color or odor to urine",
"kidney mass",
"swollen abdomen",
"symptoms of prostate",
"leg stiffness or tightness",
"difficulty breathing",
"rib pain",
"joint pain",
"muscle stiffness or tightness",
"pallor",
"hand or finger lump or mass",
"chills",
"groin pain",
"fatigue",
"abdominal distention",
"regurgitation",
"symptoms of the kidneys",
"melena",
"flushing",
"coughing up sputum",
"seizures",
"delusions or hallucinations",
"shoulder cramps or spasms",
"joint stiffness or tightness",
"pain or soreness of breast",
"excessive urination at night",
"bleeding from eye",
"rectal bleeding",
"constipation",
"temper problems",
"coryza",
"wrist weakness",
"eye strain",
"hemoptysis",
"lymphedema",
"skin on leg or foot looks infected",
"allergic reaction",
"congestion in chest",
"muscle swelling",
"pus in urine",
"abnormal size or shape of ear",
"low back weakness",
"sleepiness",
"apnea",
"abnormal breathing sounds",
"excessive growth",
"elbow cramps or spasms",
"feeling hot and cold",
"blood clots during menstrual periods",
"absence of menstruation",
"pulling at ears",
"gum pain",
"redness in ear",
"fluid retention",
"flu-like syndrome",
"sinus congestion",
"painful sinuses",
"fears and phobias",
"recent pregnancy",
"uterine contractions",
"burning chest pain",
"back cramps or spasms",
"stiffness all over",
"muscle cramps, contractures, or spasms",
"low back cramps or spasms",
"back mass or lump",
"nosebleed",
"long menstrual periods",
"heavy menstrual flow",
"unpredictable menstruation",
"painful menstruation",
"infertility",
"frequent menstruation",
"sweating",
"mass on eyelid",
"swollen eye",
"eyelid swelling",
"eyelid lesion or rash",
"unwanted hair",
"symptoms of bladder",
"irregular appearing nails",
"itching of skin",
"hurts to breath",
"nailbiting",
"skin dryness, peeling, scaliness, or roughness",
"skin on arm or hand looks infected",
"skin irritation",
"itchy scalp",
"hip swelling",
"incontinence of stool",
"foot or toe cramps or spasms",
"warts",
"bumps on penis",
"too little hair",
"foot or toe lump or mass",
"skin rash",
"mass or swelling around the anus",
"low back swelling",
"ankle swelling",
"hip lump or mass",
"drainage in throat",
"dry or flaky scalp",
"premenstrual tension or irritability",
"feeling hot",
"feet turned in",
"foot or toe stiffness or tightness",
"pelvic pressure",
"elbow swelling",
"elbow stiffness or tightness",
"early or late onset of menopause",
"mass on ear",
"bleeding from ear",
"hand or finger weakness",
"low self-esteem",
"throat irritation",
"itching of the anus",
"swollen or red tonsils",
"irregular belly button",
"swollen tongue",
"lip sore",
"vulvar sore",
"hip stiffness or tightness",
"mouth pain",
"arm weakness",
"leg lump or mass",
"disturbance of smell or taste",
"discharge in stools",
"penis pain",
"loss of sex drive",
"obsessions and compulsions",
"antisocial behavior",
"neck cramps or spasms",
"pupils unequal",
"poor circulation",
"thirst",
"sleepwalking",
"skin oiliness",
"sneezing",
"bladder mass",
"knee cramps or spasms",
"premature ejaculation",
"leg weakness",
"posture problems",
"bleeding in mouth",
"tongue bleeding",
"change in skin mole size or color",
"penis redness",
"penile discharge",
"shoulder lump or mass",
"polyuria",
"cloudy eye",
"hysterical behavior",
"arm lump or mass",
"nightmares",
"bleeding gums",
"pain in gums",
"bedwetting",
"diaper rash",
"lump or mass of breast",
"vaginal bleeding after menopause",
"infrequent menstruation",
"mass on vulva",
"jaw pain",
"itching of scrotum",
"postpartum problems of the breast",
"eyelid retracted",
"hesitancy",
"elbow lump or mass",
"muscle weakness",
"throat redness",
"joint swelling",
"tongue pain",
"redness in or around nose",
"wrinkles on skin",
"foot or toe weakness",
"ear drainage",
"conjunctival discharge",
"lip dryness",
"burning head pain",
"ear clicking",
"lip pain",
"ear itching",
"ear pressure",
"crusty eyelid",
"hoarseness",
"behavioral problems",
"loss of consciousness",
"disturbance of vision",
"abnormal appearing eye",
"symptoms of voice",
"retrosternal pain",
"itchy breast",
"painful breast lump",
"tightness in throat",
"lump or mass in throat",
"vaginal odor",
"hot and dry skin",
"disturbance in social behavior",
"genital pain",
"face is warm",
"intolerance of cold",
"facial pain",
"abscess of breast",
"premature labor",
"pregnancy complication",
"breath smells bad",
"lack of emotional control",
"voice changes",
"back redness",
"neck lump or mass",
"itchy genitals",
"burning pain during intercourse",
"anxiety attacks",
"urinary urgency",
"abnormal stool frequency",
"skin changes during pregnancy",
"painful stool",
"abscess of mouth",
"tingling of skin",
"burning pain in chest",
"delayed puberty",
"vomiting in infants",
"swelling of the penis",
"depression postpartum",
"blood in vomit",
"itchy penis",
"rectal discharge",
"swelling in area of ovary",
"itching of the vulva",
"itching of the vagina",
"symptoms of the tongue",
"itching of the mouth",
"retrosternal burning",
"intolerance of heat",
"excessive crying",
"breast pain during pregnancy",
"breast pain postpartum",
"itchy skin rash",
"abnormal stool color",
"irregular menstruation",
"jaw stiffness",
"elbow redness",
"redness of the mouth or tongue",
"redness of the hand or finger",
"bleeding or discharge from penis",
"failure to thrive",
"psychological symptoms",
"delayed development",
"painful periods",
"abnormal movement",
"problems with face or jaw size or shape",
"abnormal menstruation",
"bowel incontinence",
"itching of the breast",
"toe walking",
"ear wax problems",
"jaw weakness",
"chronic cough",
"swelling of hands or fingers",
"injury",
"rash in infants",
"stiffness of spine",
"abdominal mass",
"mass in pelvic area",
"foot or toe redness",
"neck weakness",
"pelvic weakness",
"shaking",
"abnormal movement of ankle",
"redness in skin of arm",
"nail ridges",
"lethargy",
"abscess of skin",
"burning leg pain",
"agitation",
"child - sleeping problem",
"child - toilet training problems",
"abdominal pain - infant",
"swelling of leg",
"swelling of hand",
"hand redness",
"dryness in ear",
"stiffness in low back",
"scrotal mass",
"mouth bleeding",
"difficulty using arm",
"ear bleeding",
"conjunctival redness",
"bleeding in eye",
"vision loss",
"uncoordinated movement",
"blackheads",
"bipolar symptoms",
"white patch in mouth",
"hip cramps or spasms",
"joint redness",
"hip weakness",
"loss of balance",
"rash on hands or fingers",
"cold sweats",
"redness of the scalp",
"redness of the foot or toe",
"eyelid twitch",
"hot and sweaty skin",
"vaginal burning",
"burning feet",
"vulvar redness",
"crawling sensation on skin",
"redness in ankle",
"ear ringing",
"low mood",
"lower back pain",
"hair loss",
"loss of control of legs",
"abnormal stool",
"painful erection",
"feeling of suffocation",
"ear redness",
"pain in bones",
"infant colic",
"mass of skin",
"problems with menstruation",
"cramps all over",
"fluid from nose",
"discharge from anus",
"sores on gums",
"barking cough",
"coughing up blood",
"swelling of ankle",
"blood in sputum",
"groin weakness",
"body swelling",
"falling hair",
"pus in eye",
"abdominal tenderness",
"jaw cramps or spasms",
"high blood pressure",
"pressure in ear",
"skin on leg or foot looks abnormal",
"earache",
"baby blues",
"swelling of mouth",
"crusting of ear",
"abdominal discomfort",
"lesions of penis",
"forearm pain",
"feeling smothered",
"joint pain all over",
"elbow pain",
"disturbance of thought content",
"jaw stiffness",
"red spots on skin",
"skin is hard",
"swollen joints",
"swelling of knee",
"spots on nails",
"abdominal cramps",
"skin irritation",
"wheezing in infants",
"ear pain in infants",
"ankle stiffness or tightness",
"eye pain in infants",
"skin rash in infants",
"swelling of face",
"swelling of thigh",
"stiff neck",
"eye swelling in infants",
"excessive blinking",
"pain of skin",
"shoulder pain",
"mouth soreness",
"red spots in infants",
"ear canal lesion",
"peeling skin in infants",
"mood swings",
"baby blues",
"ear pressure",
"skin on buttocks looks abnormal",
"upper back pain",
"dry mouth",
"hair growth during pregnancy",
"discharge from breast",
"redness in nose",
"nail growth",
"eye redness in infants",
"weight loss",
"constipation in infants",
"constipation postpartum",
"burning vaginal pain",
"burning sensation during urination",
"shaky hands or fingers",
"abnormal smell of stool",
"lower back pain during pregnancy",
"burning leg pain",
"arm redness",
"white patch in mouth",
"face looks abnormal",
"painful bowel movements",
"trouble sleeping",
"rectal pain",
"cramping pain",
"eye discharge",
"chronic pain",
"excessive salivation",
"abscess of mouth",
"chronic cough",
"swelling of hands or fingers",
"bleeding gums",
"mouth ulcers",
"facial redness",
"swollen tongue",
"vomiting blood",
"lower back cramps or spasms",
"eye deviation",
"diminished vision",
"double vision",
"cross-eyed",
"pain in eye",
"eye moves abnormally",
"abnormal movement of eyelid",
"foreign body sensation in eye",
"irregular appearing scalp",
"swollen lymph nodes",
"vaginal dryness",
"painful urination",
"involuntary urination",
"pain during intercourse",
"frequent urination",
"lower abdominal pain",
"vaginal discharge",
"blood in urine",
"abnormal appearing eye",
"symptoms of voice",
"retrosternal pain",
"itchy breast",
"painful breast lump",
"tightness in throat",
"lump or mass in throat",
"vaginal odor",
"hot and dry skin",
"disturbance in social behavior",
"genital pain",
"face is warm",
"intolerance of cold",
"abnormal stool frequency",
"skin changes during pregnancy",
"painful stool",
"abscess of mouth",
"tingling of skin",
"burning pain in chest",
"delayed puberty",
"vomiting in infants",
"swelling of the penis",
"depression postpartum",
"blood in vomit",
"itchy penis",
"rectal discharge",
"swelling in area of ovary",
"itching of the vulva",
"itching of the vagina",
"abnormal menstruation",
"bowel incontinence",
"itching of the breast",
"toe walking",
"ear wax problems",
"jaw weakness",
"rash in infants",
"stiffness of spine",
"abdominal mass",
"mass in pelvic area",
"foot or toe redness",
"neck weakness",
"pelvic weakness",
"shaking",
"abnormal movement of ankle",
"redness in skin of arm",
"nail ridges",
"lethargy",
"abscess of skin",
"burning leg pain",
"agitation",
"child - sleeping problem",
"child - toilet training problems",
"abdominal pain - infant",
"swelling of leg",
"swelling of hand",
"hand redness",
"dryness in ear",
"stiffness in low back",
"scrotal mass",
"mouth bleeding",
"difficulty using arm",
"ear bleeding",
"conjunctival redness",
"bleeding in eye",
"vision loss",
"uncoordinated movement",
"blackheads",
"bipolar symptoms",
"white patch in mouth",
"hip cramps or spasms",
"joint redness",
"hip weakness",
"loss of balance",
"rash on hands or fingers",
"cold sweats",
"redness of the scalp",
"redness of the foot or toe",
"eyelid twitch",
"hot and sweaty skin",
"vaginal burning",
"burning feet",
"vulvar redness",
"crawling sensation on skin",
"redness in ankle",
"ear ringing",
"low mood",
"lower back pain",
"hair loss",
"loss of control of legs",
"abnormal stool",
"painful erection",
"feeling of suffocation",
"ear redness",
"pain in bones",
"infant colic",
"mass of skin",
"problems with menstruation",
"cramps all over",
"fluid from nose",
"discharge from anus",
"sores on gums",
"barking cough",
"coughing up blood",
"swelling of ankle",
"blood in sputum",
"groin weakness",
"body swelling",
"falling hair",
"pus in eye",
"abdominal tenderness",
"jaw cramps or spasms",
"high blood pressure",
"pressure in ear",
"skin on leg or foot looks abnormal",
"earache",
"baby blues",
"swelling of mouth",
"crusting of ear",
"abdominal discomfort",
"lesions of penis",
"forearm pain",
"feeling smothered",
"joint pain all over",
"elbow pain",
"disturbance of thought content",
"jaw stiffness",
"red spots on skin",
"skin is hard",
"swollen joints",
"swelling of knee",
"spots on nails",
"abdominal cramps",
"skin irritation",
"wheezing in infants",
"ear pain in infants",
"ankle stiffness or tightness",
"eye pain in infants",
"skin rash in infants",
"swelling of face",
"swelling of thigh",
"stiff neck",
"eye swelling in infants",
"excessive blinking",
"pain of skin",
"shoulder pain",
"mouth soreness",
"red spots in infants",
"ear canal lesion",
"peeling skin in infants",
"mood swings",
"baby blues",
"ear pressure",
"skin on buttocks looks abnormal",
"upper back pain",
"dry mouth",
"hair growth during pregnancy",
"discharge from breast",
"redness in nose",
"nail growth",
"eye redness in infants",
"weight loss",
"constipation in infants",
"constipation postpartum",
"burning vaginal pain",
"burning sensation during urination",
"shaky hands or fingers",
"abnormal smell of stool",
"lower back pain during pregnancy",
"burning leg pain",
"arm redness",
"white patch in mouth",
"face looks abnormal",
"painful bowel movements",
"trouble sleeping",
"rectal pain",
"cramping pain",
"eye discharge",
"chronic pain",
"excessive salivation",
"abscess of mouth",
"chronic cough",
"swelling of hands or fingers",
"bleeding gums",
"mouth ulcers",
"facial redness",
"swollen tongue",
"vomiting blood",
"lower back cramps or spasms",
"eye deviation",
"diminished vision",
"double vision",
"cross-eyed",
"pain in eye",
"eye moves abnormally",
"abnormal movement of eyelid",
"foreign body sensation in eye",
"irregular appearing scalp",
"swollen lymph nodes",
"vaginal dryness",
"painful urination",
"involuntary urination",
"pain during intercourse",
"frequent urination",
"lower abdominal pain",
"vaginal discharge",
"blood in urine",
"hot flashes",
"intermenstrual bleeding",
"hand or finger pain",
"wrist pain",
"hand or finger swelling",
"arm pain",
"wrist swelling",
"arm stiffness or tightness",
"arm swelling",
"hand or finger stiffness or tightness",
"wrist stiffness or tightness",
"lip swelling",
"toothache",
"abnormal appearing skin",
"skin lesion",
"acne or pimples",
"dry lips",
"facial pain",
"mouth ulcer",
"skin growth",
"eye deviation",
"diminished vision",
"double vision",
"cross-eyed",
"symptoms of eye",
"pain in eye",
"eye moves abnormally",
"abnormal movement of eyelid",
"foreign body sensation in eye",
"irregular appearing scalp",
"swollen lymph nodes",
"back pain",
"neck pain",
"low back pain",
"pain of the anus",
"pain during pregnancy",
"pelvic pain",
"impotence",
"infant spitting up",
"vomiting blood",
"regurgitation",
"burning abdominal pain",
"restlessness",
"symptoms of infants",
"wheezing",
"peripheral edema",
"neck mass",
"ear pain",
"jaw swelling",
"mouth dryness",
"neck swelling",
"knee pain",
"foot or toe pain",
"bowlegged or knock-kneed",
"ankle pain",
"bones are painful",
"knee weakness",
"elbow pain",
"knee swelling",
"skin moles",
"knee lump or mass",
"weight gain",
"problems with movement",
"knee stiffness or tightness",
"leg swelling",
"foot or toe swelling",
"heartburn",
"smoking problems",
"muscle pain",
"infant feeding problem",
"recent weight loss",
"problems with shape or size of breast",
"underweight",
"difficulty eating",
"scanty menstrual flow",
"vaginal pain",
"vaginal redness",
"vulvar irritation",
"weakness",
"decreased heart rate",
"increased heart rate",
"bleeding or discharge from nipple",
"ringing in ear",
"plugged feeling in ear",
"itchy ear(s)",
"frontal headache",
"fluid in ear",
"neck stiffness or tightness",
"spots or clouds in vision",
"eye redness",
"lacrimation",
"itchiness of eye",
"blindness",
"eye burns or stings",
"itchy eyelid",
"feeling cold",
"decreased appetite",
"excessive appetite",
"excessive anger",
"loss of sensation",
"focal weakness",
"slurring words",
"symptoms of the face",
"disturbance of memory",
"paresthesia",
"side pain",
"fever",
"shoulder pain",
"shoulder stiffness or tightness",
"shoulder weakness",
"arm cramps or spasms",
"shoulder swelling",
"tongue lesions",
"leg cramps or spasms",
"abnormal appearing tongue",
"ache all over",
"lower body pain",
"problems during pregnancy",
"spotting or bleeding during pregnancy",
"cramps and spasms",
"upper abdominal pain",
"stomach bloating",
"changes in stool appearance",
"unusual color or odor to urine",
"kidney mass",
"swollen abdomen",
"symptoms of prostate",
"leg stiffness or tightness",
"difficulty breathing",
"rib pain",
"joint pain",
"muscle stiffness or tightness",
"pallor",
"hand or finger lump or mass",
"chills",
"groin pain",
"fatigue",
"abdominal distention",
"regurgitation.1",
"symptoms of the kidneys",
"melena",
"flushing",
"coughing up sputum",
"seizures",
"delusions or hallucinations",
"shoulder cramps or spasms",
"joint stiffness or tightness",
"pain or soreness of breast",
"excessive urination at night",
"bleeding from eye",
"rectal bleeding",
"constipation",
"temper problems",
"coryza",
"wrist weakness",
"eye strain",
"hemoptysis",
"lymphedema",
"skin on leg or foot looks infected",
"allergic reaction",
"congestion in chest",
"muscle swelling",
"pus in urine",
"abnormal size or shape of ear",
"low back weakness",
"sleepiness",
"apnea",
"abnormal breathing sounds",
"excessive growth",
"elbow cramps or spasms",
"feeling hot and cold",
"blood clots during menstrual periods",
"absence of menstruation",
"pulling at ears",
"gum pain",
"redness in ear",
"fluid retention",
"flu-like syndrome",
"sinus congestion",
"painful sinuses",
"fears and phobias",
"recent pregnancy",
"uterine contractions",
"burning chest pain",
"back cramps or spasms",
"stiffness all over",
"muscle cramps, contractures, or spasms",
"low back cramps or spasms",
"back mass or lump",
"nosebleed",
"long menstrual periods",
"heavy menstrual flow",
"unpredictable menstruation",
"painful menstruation",
"infertility",
"frequent menstruation",
"sweating",
"mass on eyelid",
"swollen eye",
"eyelid swelling",
"eyelid lesion or rash",
"unwanted hair",
"symptoms of bladder",
"irregular appearing nails",
"itching of skin",
"hurts to breath",
"nailbiting",
"skin dryness, peeling, scaliness, or roughness",
"skin on arm or hand looks infected",
"skin irritation",
"itchy scalp",
"hip swelling",
"incontinence of stool",
"foot or toe cramps or spasms",
"warts",
"bumps on penis",
"too little hair",
"foot or toe lump or mass",
"skin rash",
"mass or swelling around the anus",
"low back swelling",
"ankle swelling",
"hip lump or mass",
"drainage in throat",
"dry or flaky scalp",
"premenstrual tension or irritability",
"feeling hot",
"feet turned in",
"foot or toe stiffness or tightness",
"pelvic pressure",
"elbow swelling",
"elbow stiffness or tightness",
"early or late onset of menopause",
"mass on ear",
"bleeding from ear",
"hand or finger weakness",
"low self-esteem",
"throat irritation",
"itching of the anus",
"swollen or red tonsils",
"irregular belly button",
"swollen tongue",
"lip sore",
"vulvar sore",
"hip stiffness or tightness",
"mouth pain",
"arm weakness",
"leg lump or mass",
"disturbance of smell or taste",
"discharge in stools",
"penis pain",
"loss of sex drive",
"obsessions and compulsions",
"antisocial behavior",
"neck cramps or spasms",
"pupils unequal",
"poor circulation",
"thirst",
"sleepwalking",
"skin oiliness",
"sneezing",
"bladder mass",
"knee cramps or spasms",
"premature ejaculation",
"leg weakness",
"posture problems",
"bleeding in mouth",
"tongue bleeding",
"change in skin mole size or color",
"penis redness",
"penile discharge",
"shoulder lump or mass",
"polyuria",
"cloudy eye",
"hysterical behavior",
"arm lump or mass",
"nightmares",
"bleeding gums",
"pain in gums",
"bedwetting",
"diaper rash",
"lump or mass of breast",
"vaginal bleeding after menopause",
"infrequent menstruation",
"mass on vulva",
"jaw pain",
"itching of scrotum",
"postpartum problems of the breast",
"eyelid retracted",
"hesitancy",
"elbow lump or mass",
"muscle weakness",
"throat redness",
"joint swelling",
"tongue pain",
"redness in or around nose",
"wrinkles on skin",
"foot or toe weakness",
"hand or finger cramps or spasms",
"back stiffness or tightness",
"wrist lump or mass",
"skin pain",
"low back stiffness or tightness",
"low urine output",
"skin on head or neck looks infected",
"stuttering or stammering",
"problems with orgasm",
"nose deformity",
"lump over jaw",
"sore in nose",
"hip weakness",
"back swelling",
"ankle stiffness or tightness",
"ankle weakness",
"neck weakness"
      // Add more symptoms as needed
    ];

    setOptions(allSymptoms.map(symptom => ({ label: symptom })));
  }, []);

  const handleInputChange = (selected) => {
    // Extract labels from selected options
    setSymptoms(selected.map(item => item.label).join(', '));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);

    try {
      const response = await fetch('https://vraj.atjam.study/batch', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ symptoms: symptoms }),
      });

      if (!response.ok) {
        throw new Error('Failed to fetch prediction');
      }

      const data = await response.json();
      setPredictionResult(data);
    } catch (error) {
      console.error('Error predicting:', error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="App">
       <h1 className="disease-predictor-title">Disease Cluster Predictor:</h1>
       <h2 className="symptoms-heading">Here you can either select one or many symptoms. You have 376 options to enter your appropriate symptom(s)</h2>

      <div className="PredictDisease">
        <h1>Enter Symptoms</h1>
        <form onSubmit={handleSubmit}>
          <label htmlFor="symptoms">Symptoms (comma-separated):</label><br />
          <Typeahead
            id="symptoms"
            multiple
            options={options}
            onChange={handleInputChange}
            placeholder="Select symptoms..."
          /><br /><br />
          <button type="submit" disabled={loading}>Predict</button>
        </form>

        {loading && <p>Loading...</p>}

        {predictionResult && (
          <div className="prediction-result">
            <b><h2>Predicted Results</h2></b>
            <p><b><u>Predicted Disease:</u></b> {predictionResult.cluster}</p>
            <p><b><u>Predicted Disease Cluster:</u></b> {'{'}
              {predictionResult.disease.map((disease, index) => (
                <React.Fragment key={index}>
                  {index > 0 && ', '}
                  {disease}
                </React.Fragment>
              ))}
            {'}'}</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default Otherss;
